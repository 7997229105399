import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import { getMainLayout } from '@layouts/MainLayout';

import InviteIndex from './jobs/Invites';
import { NextPageWithLayout } from './_app';

const Index: NextPageWithLayout = () => {
  const router = useRouter();

  useEffect(() => {
    if (router.isReady) router.push('/jobs/Invites');
  }, [router]);
  return (
    <>
      <InviteIndex />
    </>
  );
};

Index.getLayout = getMainLayout;

export default Index;
